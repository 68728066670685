import { Injectable } from '@angular/core';
import { Credentials } from './credentials.model';

const credentialsKey = 'credentials';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  public _credentials: {} | any;

  constructor() {}

  public setCredentials(credentials?: Credentials) {
    this._credentials = credentials || null;
    if (credentials) {
      const storage = localStorage;
      storage.setItem(credentialsKey, JSON.stringify(credentials));
    } else {
      sessionStorage.removeItem(credentialsKey);
      localStorage.removeItem(credentialsKey);
      sessionStorage.clear();
      localStorage.clear();
    }
  }

  public getCredentials() {
    return (
      sessionStorage.getItem(credentialsKey) ||
      localStorage.getItem(credentialsKey)
    );
  }
  public getSearchCredentials() {
    return (
      sessionStorage.getItem(credentialsKey) ||
      localStorage.getItem(credentialsKey)
    );
  }
  public getCurrentUser() {
    const savedCredentials = this.getCredentials();

    this._credentials = savedCredentials ? JSON.parse(savedCredentials) : null;
    return this._credentials ? this._credentials['authKey'] : null;
  }

  public getToken(): string | null {
    const savedCredentials = this.getCredentials();
    this._credentials = savedCredentials ? JSON.parse(savedCredentials) : null;
    return this._credentials ? this._credentials['authToken'] : null;
  }
  public getKey(): string | null {
    const savedCredentials = this.getCredentials();
    this._credentials = savedCredentials ? JSON.parse(savedCredentials) : null;
    return this._credentials ? this._credentials['authKey'] : null;
  }
  public getAuthId(): string | null {
    const savedCredentials = this.getCredentials();
    this._credentials = savedCredentials ? JSON.parse(savedCredentials) : null;
    return this._credentials ? this._credentials['authId'] : null;
  }
  public getCsrfToken(): string | null {
    const savedCredentials = this.getCredentials();
    this._credentials = savedCredentials ? JSON.parse(savedCredentials) : null;
    return this._credentials ? this._credentials['csrf'] : null;
  }

  public getSavedLang() {
    const savedCredentials = this.getCredentials();
    this._credentials = savedCredentials ? JSON.parse(savedCredentials) : null;
    return this._credentials && this._credentials['lang']
      ? this._credentials['lang']
      : 'en';
  }
}
