<footer>
    <div class="footer-container">
        <img class="footer-pad" src="../../../assets/images/main_logo.png" alt="CellGate Logo"> 
        <div class="footer-label">&copy; 2023 CellGate | <a class="footer-link" href="https://cell-gate.com/privacy-policy/" target="_blank">Privacy Policy</a></div>
    </div>
</footer>

<!-- /////////////***************************///////////// -->

<!-- <footer>
    <div class="footer">
        <div class="container">
            <div class="footer-wrapper">
                <div class="row text-center">
                    <img class="footer-pad" src="../../../assets/images/main_logo.png" alt="CellGate Logo"> 
                        <label for="copyright" class="footer-link">&copy; 2023 CellGate |</label>
                        <a class="footer-link" href="https://cell-gate.com/privacy-policy/" target="_blank">  Privacy Policy</a>
                </div>
            </div>
        </div>
    </div>
</footer> -->