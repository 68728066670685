import type {
    HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AuthenticationService } from '../services/http/authentication/authentication.service';
import { Observable } from 'rxjs/internal/Observable';
import { APP_DI_CONFIG } from 'src/app/Core/config/app.config';
import { catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  headersConfig: any;
  isfromportalIs_V:any=APP_DI_CONFIG.Is_E; 
  constructor(private authService: AuthenticationService, private router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.headersConfig = {
      Accept: 'application/json',
    };

    const token = 'Bearer ' + this.authService.getToken();
    const key = this.authService.getKey();
    const isfromportal = this.isfromportalIs_V;
    if (token) {
      this.headersConfig['AuthToken'] = `${token}`;
      this.headersConfig['AuthKey'] = `${key}`;
      this.headersConfig['Is_E'] = `${isfromportal}`;
      this.headersConfig['Is_IV'] = `${isfromportal}`;
      this.headersConfig['responseType'] = 'blob';
    }

    const csrfToken = this.authService.getCsrfToken();
    if (csrfToken) {
      this.headersConfig['X-CSRF-Token'] = csrfToken;
    }

    const request = req.clone({ setHeaders: this.headersConfig });

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && error.statusText == "Unauthorized") {
          const authToken = this.authService.getToken();
          if (authToken) {
            alert("The session time has expired. Please re-login again!");
          }
          localStorage.clear();
          this.router.navigate(['/auth/login']);  
        }
        return throwError(error);
      })
    );;
  }
}
