// #docregion token
import { InjectionToken } from '@angular/core';
import { IAppConfig } from './IApp.config';
export { IAppConfig } from './IApp.config';
import { environment } from '../../../environments/environment';
import { APP_ROLES_CONSTANT } from './app.constant';
export const APP_CONFIG = new InjectionToken<IAppConfig>('app.config');
// #enddocregion token
// #docregion config
export const APP_DI_CONFIG: IAppConfig = {
  site_name: environment.SITE_NAME,
  copyright_text: environment.COPYRIGHT_TEXT,
  version: environment.version,
  isVersionCheck: environment.isVersionCheck,
  build_version: environment.build_version,
  appLogo: 'assets/images/logo.svg',
  DEFAULT_PAGINATION_LIMIT: environment.DEFAULT_PAGINATION_LIMIT || 10,
  SE:'MTIzNDU2MDAwMDAwMDAwMA==',
  VK:'MDAwMDAwMDAwMDAwMDAwMA==',
  Is_E:'EPZaRmURyw1p6pdnSy8zdA=='
};
