import { IAPIParams } from "./IapiParam";

export function APIURL({
  Accountkit,
  VoiceVideo,
  Log,
  DeviceSetting,
  DeviceAction,
  BulkImport,
  DataLayer,
  pkPassFile,
  Emailnotification,
  ExportData
}: IAPIParams) {
  return {
    pkPassFile: {
      url: `${pkPassFile}`
    },
    Accountkit: {
      url: `${Accountkit}`,
    },
    VoiceVideo: {
      url:`${VoiceVideo}`,
      login: `${VoiceVideo}/api/ImportValidatorUsers/Login`,
      SignInAsAdmin: `${VoiceVideo}/api/ImportValidatorUsers/SignInAsAdmin`,
      ForgotPassword: `${VoiceVideo}/api/ImportValidatorUsers/ForgotPassword?email=`,
      ResetPassword: `${VoiceVideo}/api/ImportValidatorUsers/ResetPassword`,
      validateResetPasswordLink: `${VoiceVideo}/api/ImportValidatorUsers/validateResetPasswordLink?passwordResetToken=`,
      getUserDetails: `${VoiceVideo}/api/ImportValidatorUsers/GetUserDetails`,
      UserSignout: `${VoiceVideo}/api/ImportValidatorUsers/UserSignout?userID=`,
      GetDashboard: `${VoiceVideo}/api/ImportValidatorUsers/GetDashboard`,
      UpdateUserPassword: `${VoiceVideo}/api/ImportValidatorUsers/UpdateUserPassword`,
      getUser: `${VoiceVideo}/api/ImportValidatorUsers/getUser?userId=`,
      getdealerAccount: `${VoiceVideo}/api/ImportValidatorUsers/GetDealerUserAccountDetails?userId=`,
      ImportValidatorSaveGroupData: `${VoiceVideo}/api/ImportValidatorUsers/ImportValidatorSaveImportGroupData`,
      ExportImportGroupData: `${VoiceVideo}/api/ImportValidatorUsers/ImportValidatorExportImportGroupData`,
      ExportImportErrorGroupData: `${VoiceVideo}/api/ImportValidatorUsers/ImportValidatorExportErrorImportGroupData`,
      CheckImportValidatorErrorLogFile: `${VoiceVideo}/api/ImportValidatorUsers/CheckImportValidatorErrorLogFile`,
      GetImportValidatorUser: `${VoiceVideo}/api/ImportValidatorUsers/GetImportValidatorUser?passwordResetToken=`
    },
    Log: {
      url: `${Log}`,
    },
    DeviceSetting: {
      url: `${DeviceSetting}`,
    },
    DeviceAction: {
      url: `${DeviceAction}`,
    },
    BulkImport: {
      url: `${BulkImport}`,
      GetGroupInfo: `${BulkImport}/api/ImportGroup/GetUserGroupInfo?accountId=`,
    },
    DataLayer: {
      url: `${DataLayer}`,
    },
    Emailnotification: {
      url: `${Emailnotification}`,
    },
    ExportData: {
      url: `${ExportData}`,
    }
  };
}
