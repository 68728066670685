import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
  type CanActivateFn,
} from '@angular/router';
import { AuthenticationService } from '../services/http/authentication/authentication.service';
import { Observable, of } from 'rxjs';
import { ImportValidatorService } from '../services/http/importvalidator/import-validator.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
    return this.checkUser(next, state);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    return this.checkUser(route, state);
  }

  private checkUser(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    if (this.authService.isAuthenticated()) {
      if (state.url.includes('reset-password')) return true;
      else return this.router.parseUrl('/site/importvalidator');
    } else {
      // not logged in so redirect to login page with the return url
      return true;
      // this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
    }
  }
}

@Injectable()
export class UserLoad implements CanLoad {
  constructor(
    private userService: ImportValidatorService,
    private authService: AuthenticationService
  ) {}
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    if (this.userService.UserDetailsList) return of(true);
    return this.authService.getUserDetailsModule();
  }
}

@Injectable()
export class SiteGuard implements CanActivate {
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    if (this.authService.isAuthenticated()) {
      return true;
    } else {
      return this.router.parseUrl('/auth');
    }
  }
}
