import { Component, HostListener } from '@angular/core';
import { handleKeyPress, handlePaste, handleInput } from './security/input-handler.function';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'importvalidator';
  private notallowedCharacters = /^[<>]*$/;
  private maliciousPatterns = /(https?:|ftp:|file:|http:|mailto:|data:|javascript:|vbscript:|<[^>]*>|=HYPERLINK\([^)]*\)|\.(exe|bat|cmd|js|vbs|html|php))/i;
  
  @HostListener('document:keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    handleKeyPress(event);
  }

  @HostListener('document:paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    handlePaste(event);
  }

  @HostListener('document:input', ['$event'])
  onInput(event: InputEvent){
    handleInput(event);
  }
}
