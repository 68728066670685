import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiHandler } from 'src/app/Core/services/api.handler';
import { HttpHeaders } from '@angular/common/http';
import {saveAs} from 'file-saver-es';

@Injectable({
  providedIn: 'root'
})
export class ImportValidatorService {

  constructor() { }
  
  private apiHandler = inject(ApiHandler);
  UserDetailsList: any = null;
  private USER = new BehaviorSubject<any>(this.UserDetailsList);
  UserDetails = this.USER.asObservable();
  
  setUser(user: any) {
    this.UserDetailsList = user;
    this.USER.next(user);
  }

  getGroupInfo(accountId: number,searchText: string|null = null,searchFilter: string|null = null) {
    let url = `${environment.API_URL.BulkImport.GetGroupInfo}${accountId}`+`&searchText=`+searchText+`&searchFilter=`+searchFilter;
    return this.apiHandler.get(url).pipe(
      map((response: any) => {
        if (response)
        return response || [];
      }),
      catchError(this.handleError)
    );
  }

  saveImportFile(importdata:any) {

    var formData = new FormData();
    formData.append('importFile', importdata.ImportFile);
    formData.append('userId', importdata.UserId);
    formData.append('blobFileName', importdata.BlobFileName);
    formData.append('FileExtension', importdata.FileExtension);
   
    let httpHeader = new HttpHeaders();
    httpHeader.set("Content-Type", "multipart/form-data");
    httpHeader.set("Accept", "multipart/form-data");
    let options: any= {
        responseType: 'arraybuffer'
    }
    return this.apiHandler.postForImport(
      environment.API_URL.VoiceVideo.ImportValidatorSaveGroupData,
      formData,
      options
     ).
     pipe(
      map(data => {
        // if(importdata.IsDoorking){
        //   let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        //   saveAs(blob, 'Standard Template.xlsx');

        // }
        // else {
          var uarray:any =  new Uint8Array(data);
          return JSON.parse(String.fromCharCode.apply(null, uarray));
        //}
      })
     );
  }


  SendEmailToCustomerWhoLoginOrSchedule(data: any) {
    return this.apiHandler
      .post(
        environment.API_URL.BulkImport.url + '/api/ImportValidatorUsers/SendEmailToCustomerWhoLoginOrScheduleBulkImportValidator',
        data
      )
      .pipe(
        map((respose) => {
          return respose;
        })
      )
      .pipe(catchError(this.handleError));
  }
  getProgressBarData(accountID:any) {

    let options: any = {
      responseType: 'arraybuffer',
  };
  

    return this.apiHandler.get(environment.API_URL.BulkImport.url + "/api/ImportGroup/GetProgressBarData?accountId=" + accountID,options)
      .pipe(map((response:any) => {
        var data = response ? response : null;
        return data;
      }));
  }
  downloadErrorLogFile(userID: any, errorLogFileName: any, importFileName: any,isVoice:any, isDoorking:any, isImport:any, isFacilityCode:any) {
    var requiredData = {
        UserID: userID,
        ErrorLogFileName: errorLogFileName || "",
        ImportFileName: importFileName || "",
        IsDoorking: isDoorking, IsVoice: isVoice,
        IsImport: isImport, IsFacilityCode: isFacilityCode, 
    };

    let options: any = {
        responseType: 'arraybuffer',
        headers: {}
    };

    return this.apiHandler.postForImport(
        environment.API_URL.VoiceVideo.ExportImportGroupData,
        requiredData,
        options
    ).pipe(
        map(data => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            if (blob.size > 0) {
              let fileName = errorLogFileName ? `${errorLogFileName}.xlsx` : `${importFileName}.xlsx`;
              saveAs(blob, fileName);
          }
        })
    );
}
downloadErrorLogFileData(userID: any, errorLogFileName: any, importFileName: any,isVoice:any, isDoorking:any, isImport:any, isFacilityCode:any) {
  var requiredData = {
      UserID: userID,
      ErrorLogFileName: errorLogFileName || "",
      ImportFileName: importFileName || "",
      IsDoorking: isDoorking, IsVoice: isVoice,
      IsImport: isImport, IsFacilityCode: isFacilityCode, 
  };

  let options: any = {
      responseType: 'arraybuffer',
      headers: {}
  };

  return this.apiHandler.postForImport(
      environment.API_URL.VoiceVideo.ExportImportErrorGroupData,
      requiredData,
      options
  ).pipe(
      map(data => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

          if (blob.size > 0) {
            let fileName = errorLogFileName ? `${errorLogFileName}.xlsx` : `${importFileName}.xlsx`;
            saveAs(blob, fileName);
        }
      })
  );
}
  private handleError(error: any) {
    return of(error.error);
  }
  CheckImportValidatorErrorLogFile(ImportValidatorUserId:any){
    return this.apiHandler.get(environment.API_URL.VoiceVideo.CheckImportValidatorErrorLogFile + "?ImportValidatorUserId=" + ImportValidatorUserId).pipe(map(response => {
      return response;
    }));
  }

}

