import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiHandler {
  constructor(private http: HttpClient) {}

  private formatErrors(error: any) {
    // return Observable.throw(error.error); // use this for subscribe(error:) to fire
    // Let the app keep running by returning an empty result.
    if(error.url.includes(`${environment.API_URL.Accountkit.url}`+'/api/AccountKit/GetAdminUserDetails') && error.statusText == 'Unknown Error'){
      localStorage.clear();
      console.clear();
    }
    return of(error.error);
  }

  get(path: string,  options: any = {}, params: HttpParams = new HttpParams()): Observable<any> {
    if(options==null){
      options={};
    }
    options.params = params;
    return this.http
      .get(/^(http|https|ftp)/.test(path) ? `${path}` : `${environment.API_URL}${path}`, options)
      .pipe(catchError(this.formatErrors));
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.http.put(`${environment.API_URL}${path}`, JSON.stringify(body)).pipe(catchError(this.formatErrors));
  }

  post(path: string, body: Object = {}, options: any = { headers : {}}): Observable<any> {
    options.headers["Content-Type"] = "application/json";
    return this.http.post(/^(http|https|ftp)/.test(path) ? `${path}` : `${environment.API_URL}${path}`,
      JSON.stringify(body),
      options,
        ).pipe(catchError(this.formatErrors));
  }
  delete(path: any): Observable<any> {
    // return this.http.delete(`${environment.API_URL}${path}`).pipe(catchError(this.formatErrors));
    return this.http.delete(/^(http|https|ftp)/.test(path) ? `${path}` : `${environment.API_URL}${path}`,
        ).pipe(catchError(this.formatErrors));
  }

  postDownload(path: string, body: Object = {}): Observable<any> {

    return this.http.post(
      path,
      {
          params: body,
          responseType: 'arraybuffer',
          headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      }
    ).pipe(catchError(this.formatErrors));
  }
  postForImport(path: string, body: Object = {}, options?: any): Observable<any> {
    return this.http.post(/^(http|https|ftp)/.test(path) ? `${path}` : `${environment.API_URL}${path}`,
      body,
      options,
        ).pipe(catchError(this.formatErrors));
  }
}
